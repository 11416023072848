@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
#root {
  font-family: "Roboto", sans-serif;
}
.main-wrapper {
  background-color: rgba(235, 235, 235, 0.7);
  float: left;
  width: 66.6667%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.main-wrapper-inner {
  max-width: 400px;
  margin: 0 auto;
  height: 100vh;
  height: 100vh;
}
.main-wrapper-inner h2 {
  color: #121832;
  font-size: 24px;
  font-weight: bold;
  line-height: 17px;
}
.byline {
  font-size: 12px;
}
.sub-link {
  font-size: 12px;
  text-decoration: underline;
}

.segment-value:first-child {
  transform: rotate(0deg) translate(-170px, 30px);
}

.segment-value:last-child {
  transform: rotate(0deg) translate(170px, 30px);
}

.gm-style .gm-style-iw-d {
  overflow: visible;
}

img {
  page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-inside: avoid; /* or 'auto' */
}