@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
#root {
  font-family: "Roboto", sans-serif;
}
.main-wrapper {
  background-color: rgba(235, 235, 235, 0.7);
  float: left;
  width: 66.6667%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.main-wrapper-inner {
  max-width: 400px;
  margin: 0 auto;
  height: 100vh;
  height: 100vh;
}
.main-wrapper-inner h2 {
  color: #121832;
  font-size: 24px;
  font-weight: bold;
  line-height: 17px;
}
.byline {
  font-size: 12px;
}
.sub-link {
  font-size: 12px;
  text-decoration: underline;
}

.segment-value:first-child {
  -webkit-transform: rotate(0deg) translate(-170px, 30px);
          transform: rotate(0deg) translate(-170px, 30px);
}

.segment-value:last-child {
  -webkit-transform: rotate(0deg) translate(170px, 30px);
          transform: rotate(0deg) translate(170px, 30px);
}

.gm-style .gm-style-iw-d {
  overflow: visible;
}

img {
  page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-inside: avoid; /* or 'auto' */
}
.ant-tabs-nav-scroll {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.bar {
  position: relative;
  margin: 0 auto;
  height: 13px;
  width: 100%;
  border-radius: 50px;

  background-color: white;
  box-shadow: inset 0px 3px 6px #00000029;
}

.bar>span {
  display: block;
  position: relative;
  height: 105%;
  width: 120%;
  border-radius: 50px;
  background: linear-gradient(90deg, #4D63EB 0%, #92a8f2 100%);
  box-shadow: 
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
  overflow: hidden;
  -webkit-animation: expandWidth 3s;
          animation: expandWidth 3s;
  -webkit-animation-timing-function:ease-out;
          animation-timing-function:ease-out;
}

/* .bar>span:after {
  content: "";
  position: absolute;
  top: 0px; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg, 
    rgba(255, 255, 255, .2) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, .2) 50%, 
    rgba(255, 255, 255, .2) 75%, 
    transparent 75%, 
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}
 */

/* .loading-bar > span:after, .animate > span > span { 
  animation: load 1s infinite;
} */

@-webkit-keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@-webkit-keyframes expandWidth {
  0% { width: 0; }
}

@keyframes expandWidth {
  0% { width: 0; }
}

.tabPosition > .titleTabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll{
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}
.ant-table-thead
  > tr
  > th
  .ant-table-header-column
  .ant-table-column-sorters
  > *:not(.ant-table-column-sorter) {
  position: relative;
  word-break: normal;
}
body {
  background-color: #f0f2f5;
}
.guestPrefundingNoteLink
  > .ant-anchor-wrapper
  > .ant-anchor
  > .ant-anchor-ink::before {
  display: none !important;
}

.guestPrefundingNoteLink
  > .ant-anchor-wrapper
  > .ant-anchor
  > .ant-anchor-link {
  padding-left: 0px;
}

#messageExpand
  > .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  /* display: none; */
  padding: 0%;
}
#messageExpand > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  box-shadow: none !important;
}

#msgAccordion,
.dJWZhq {
  margin: 0px !important;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: rgb(250, 250, 250);
}

.table-striped-rows thead {
  background-color: rgb(216,221,243)
}

.table-striped-rows tr:nth-child(2n):hover > td {
  background: rgb(250, 250, 250) !important;
}

.table-small-rows .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.closed-deal-row {
  background: rgb(232 232 232) !important;
}

.closed-deal-row-grey {
  background: rgb(250, 250, 250) !important;
}
.header thead {
  background:  rgb(216,221,243) !important;
}

.titleBackground > .ant-card > .ant-card-head {
  background-color: #4961f426 !important;
}

.sticky thead {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}

.expandBtn .ant-table-expand-icon-th::before {
  width: 28px;
  font-size: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  content: '+';
  cursor: pointer;
  color: primary-color;
  height: 10px;
}

.expandBtn .table-expanding-all .ant-table-expand-icon-th::before {
  content: "-";
  font-size: 20px;
}

.sliderForeGround > .ant-slider-track {
  background-color: orange !important;
}

.notExpandable .ant-table-row-expand-icon-cell {
  visibility: hidden;
}

.table-row-dark {
  background-color: rgb(245, 239, 239);
}

.ant-table-expanded-row thead {
  background-color: inherit;
}

.ant-table-expanded-row .highlight > td {
  background: #e4e4e4 !important;
}

.ant-table-row.row-hoverable {
  cursor: pointer;
}

.ql-shortcodes{
  margin-top: 20px;
}
.ql-short_codes{
  margin-top: 20px;
}

span.ql-picker-item{
  margin-top: 5px;
  margin-bottom: 10px;
}
.tips .ant-popover-inner-content{
  background-color: rgb(244, 243, 206);
}

.leadTabletips .ant-popover-inner-content {
  background-color: rgb(248, 248, 231);
}

.yrs .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: none;
}
.lenderSelect .ant-checkbox-inner {
  background-color: #fbf8f8 !important;
  border-color: #c6c2c2 !important;
}
.lenderSelect .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4962F5 !important;
  border-color: #4962F5;
}


