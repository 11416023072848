.ant-table-thead
  > tr
  > th
  .ant-table-header-column
  .ant-table-column-sorters
  > *:not(.ant-table-column-sorter) {
  position: relative;
  word-break: normal;
}
body {
  background-color: #f0f2f5;
}
.guestPrefundingNoteLink
  > .ant-anchor-wrapper
  > .ant-anchor
  > .ant-anchor-ink::before {
  display: none !important;
}

.guestPrefundingNoteLink
  > .ant-anchor-wrapper
  > .ant-anchor
  > .ant-anchor-link {
  padding-left: 0px;
}

#messageExpand
  > .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  /* display: none; */
  padding: 0%;
}
#messageExpand > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  box-shadow: none !important;
}

#msgAccordion,
.dJWZhq {
  margin: 0px !important;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: rgb(250, 250, 250);
}

.table-striped-rows thead {
  background-color: rgb(216,221,243)
}

.table-striped-rows tr:nth-child(2n):hover > td {
  background: rgb(250, 250, 250) !important;
}

.table-small-rows .ant-table-tbody > tr > td {
  padding: 2px 16px !important;
}

.closed-deal-row {
  background: rgb(232 232 232) !important;
}

.closed-deal-row-grey {
  background: rgb(250, 250, 250) !important;
}
.header thead {
  background:  rgb(216,221,243) !important;
}

.titleBackground > .ant-card > .ant-card-head {
  background-color: #4961f426 !important;
}

.sticky thead {
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}

.expandBtn .ant-table-expand-icon-th::before {
  width: 28px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  content: '+';
  cursor: pointer;
  color: primary-color;
  height: 10px;
}

.expandBtn .table-expanding-all .ant-table-expand-icon-th::before {
  content: "-";
  font-size: 20px;
}

.sliderForeGround > .ant-slider-track {
  background-color: orange !important;
}

.notExpandable .ant-table-row-expand-icon-cell {
  visibility: hidden;
}

.table-row-dark {
  background-color: rgb(245, 239, 239);
}

.ant-table-expanded-row thead {
  background-color: inherit;
}

.ant-table-expanded-row .highlight > td {
  background: #e4e4e4 !important;
}

.ant-table-row.row-hoverable {
  cursor: pointer;
}

.ql-shortcodes{
  margin-top: 20px;
}
.ql-short_codes{
  margin-top: 20px;
}

span.ql-picker-item{
  margin-top: 5px;
  margin-bottom: 10px;
}
.tips .ant-popover-inner-content{
  background-color: rgb(244, 243, 206);
}

.leadTabletips .ant-popover-inner-content {
  background-color: rgb(248, 248, 231);
}

.yrs .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: none;
}
.lenderSelect .ant-checkbox-inner {
  background-color: #fbf8f8 !important;
  border-color: #c6c2c2 !important;
}
.lenderSelect .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4962F5 !important;
  border-color: #4962F5;
}

