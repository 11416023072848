.bar {
  position: relative;
  margin: 0 auto;
  height: 13px;
  width: 100%;
  border-radius: 50px;

  background-color: white;
  box-shadow: inset 0px 3px 6px #00000029;
}

.bar>span {
  display: block;
  position: relative;
  height: 105%;
  width: 120%;
  border-radius: 50px;
  background: linear-gradient(90deg, #4D63EB 0%, #92a8f2 100%);
  box-shadow: 
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
  overflow: hidden;
  animation: expandWidth 3s;
  animation-timing-function:ease-out;
}

/* .bar>span:after {
  content: "";
  position: absolute;
  top: 0px; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg, 
    rgba(255, 255, 255, .2) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, .2) 50%, 
    rgba(255, 255, 255, .2) 75%, 
    transparent 75%, 
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}
 */

/* .loading-bar > span:after, .animate > span > span { 
  animation: load 1s infinite;
} */

@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@keyframes expandWidth {
  0% { width: 0; }
}

.tabPosition > .titleTabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll{
  flex-direction: row !important;
}